.section--header {
    display: none;
    position: relative;
    background: $color-white;
    color: $color-grey-08;
    padding-top: 0;
    padding-bottom: 0;

    @media screen and (min-width: 62em) { /* 992px */
        display: block;
    }
}

.header {
    &__logo {
        margin-top: 8px;
        margin-bottom: 8px;
        order: 0;

        @media screen and (min-width: 75em) { /* 1200px */
            margin-right: auto;
        }

        a {
            display: block;
        }

        &__img {
            width: auto;
            height: 40px;

            @media screen and (min-width: 62em) { /* 992px */
                max-width: 172px;
                height: auto;
            }
        }
    }

    &__nav {
        order: 2;

        @media screen and (min-width: 75em) { /* 1200px */
            order: 1;
        }
    }

    &__socials {
        order: 1;

        @media screen and (min-width: 75em) { /* 1200px */
            order: 2;
        }
    }
}

.social-list--header {
    margin-top: 20px;

    @media screen and (min-width: 75em) { /* 1200px */
        margin-top: 0;
        margin-bottom: 19px;
    }
}

@media screen and (min-width: 62em) { /* 992px */
    #header {
        position: relative;
        box-shadow: 0 6px 24px rgba(0,0,0,0.05);
        border-bottom: 1px solid $color-grey-01;
    }
}
