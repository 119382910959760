h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0 0 1.6rem 0;
    font-family: $primary-font;
    font-weight: $fw-03;

    /* BP xxlarge */
    @media screen and (min-width: 100em) {/* 1600px */
        margin: 0 0 2rem 0;
    }
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-weight: normal;
    line-height: 1;
    color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
    margin-top: 3rem;
}


h1, .h1 {
    font-size: 4rem;
    line-height: 1.2;

    @media screen and (min-width: 48em) {
        font-size: 4.8rem;
    }
}

h2, .h2 {
    font-size: 3.2rem;
    line-height: 1.2;

    @media screen and (min-width: 48em) {
        font-size: 4rem;
    }
}

h3, .h3 {
    font-size: 2.4rem;
    line-height: 1.33;

    @media screen and (min-width: 48em) {
        font-size: 3.2rem;
    }
}

h4, .h4 {
    font-size: 2rem;
    line-height: 1.33;

    @media screen and (min-width: 48em) {
        font-size: 2.4rem;
    }
}

h5, .h5 {
    font-size: 1.8rem;
    line-height: 1.33;
    margin-bottom: 0;

    @media screen and (min-width: 48em) {
        font-size: 2rem;
    }
}

h6, .h6 {
    font-size: 1.6rem;
    line-height: 1.66;
}

p,
ol,
ul,
dl,
address {
    margin: 0 0 1.5rem;
}

small {
    font-size: 1.28rem;
}


@media screen and (min-width: 48em) {

    h1, .h1 {
        font-size: 4.8rem;
    }

    h2, .h2 {
        font-size: 4rem;
        line-height: 1.2;
    }

    h3, .h3 {
        font-size: 3.2rem;
    }

    h4, .h4 {
        font-size: 2.4rem;
    }

    h5, .h5 {
        font-size: 2rem;
        margin-bottom: 0;
    }

}

.heading-inline {
    display: inline;
    float: left;
    padding: 0;
}

.heading-w-subtitle {
    margin-bottom: 0;
}

.mainbar h2,
.mainbar .h2,
.mainbar h3,
.mainbar .h3,
.mainbar h4,
.mainbar .h4,
.mainbar h5,
.mainbar .h5 {
    margin-top: 1.25em;
}

.mainbar > *:first-child > h2:first-child,
.mainbar > *:first-child > .h2:first-child,
.mainbar > *:first-child > h3:first-child,
.mainbar > *:first-child > .h3:first-child,
.mainbar > *:first-child > h4:first-child,
.mainbar > *:first-child > .h4:first-child,
.mainbar > *:first-child > h5:first-child,
.mainbar > *:first-child > .h5:first-child {
    margin-top: 0;
}

.mainbar h2,
.mainbar .h2 {
    color: $color-01;

    @media screen and (min-width: 48em) {
        font-size: 3.2rem;
    }
}

.mainbar h3,
.mainbar .h3 {
     @media screen and (min-width: 48em) {
        font-size: 2.4rem;
    }
}

.mainbar h4,
.mainbar .h4 {
    color: $color-grey-09;

    @media screen and (min-width: 48em) {
        font-size: 2rem;
    }
}

.mainbar h5,
.mainbar .h5 {
    @media screen and (min-width: 48em) {
        font-size: $fs-r*1.15;
    }
}
