.tile {
    display: block;
    background: #306BAC;
    background: linear-gradient(60deg, rgba(48,107,172,1) 0%, rgba(38,51,105,1) 100%);
    text-decoration: none;
    color: $color-white;
    padding: 40px;
    box-shadow: -20px 20px 40px 0 rgba(0,0,0,0.2);
    opacity: 1;
}

.tile:hover,
.tile:focus {
    opacity: 1;
    color: #fff;
}

@media screen and (min-width: 64em) { /* 1024px */
    .tile {
        height: 100%;
        padding: 48px;

        &__content {
            height: 100%;
            display: flex;
            flex-direction: column;

            &__cta {
                margin-top: auto;
            }
        }
    }
}
