
.linklist-list {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
}

.linklist-list-item {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    a {
        display: inline-block;
        text-decoration: none;
        position: relative;
        margin-left: 0;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        opacity: 0.66;
        color: $color-02;

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: calc(50% - 5px);
            left: calc(100% + 7px);
            border-left: 8px solid rgba($color-01, 0);
            border-right: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent;
            transition: all $link-transition;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            margin-left: 12px;
            opacity: 1;

            &:after {
                border-left-color: $color-01;
            }
        }
    }
}
