
.box {
    padding: 20px 15px;
}

@media screen and (min-width: 23.75em) { /* 380px */
    .box {
        padding: 20px;
    }
}
/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .box {
        padding: 30px;
    }
}

.box > *:last-child {
    margin-bottom: 0;
}
