
ul.messages {
    list-style: none;
    margin: 16px 0 25px;
    padding: 0;
}

ul.messages li {
    margin: 0 0 15px;
    padding: 10px 20px;
    vertical-align: bottom;
    text-align: left;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.2em;
}

ul.messages .error {
    background: $color-utility-warning-subtle;
    border-left: 4px solid $color-utility-warning;
    color: #FFF !important;
}

ul.messages .confirm {
    color: #FFF !important;
    background: $color-utility-positive-subtle;
    border-left: 4px solid $color-utility-positive;
}

ul.messages .warning {
    color: #FFF !important;
    background: $color-utility-alert-subtle;
    border-left: 4px solid $color-utility-alert;
}

ul.messages a {
    color: #FFF;
}