.social-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0 $fs-r*0.625;

        @media screen and (min-width: 100em) {/* 1600px */
            padding: 0 $fs-r*0.625;
        }

        a {
            color: inherit;
            display: block;
        }

        span {
            display: flex;
        }
    }

    &__item:first-child {
        padding-left: 0;
    }

    &__item:last-child {
        padding-right: 0;
    }
}
