figure {
    background-color: $color-grey-01;
    margin-top: $fs-r;
    margin-bottom: $fs-r;
    margin-left: 0;
    margin-right: 0;
    padding-top: $fs-r*1.25;
    padding-right: $fs-r*1.5;
    padding-left: $fs-r*1.5;
    padding-bottom: $fs-r*1.25;

    @media screen and (min-width: 48em) { /* 768px */
        margin-left: $fs-r*2;
        float: right;
        display: inline-block;
    }

    @media screen and (min-width: 100em) {/* 1600px */
        margin-top: $fs-r;
        margin-bottom: $fs-r;
        margin-left: $fs-r*2;
        padding-top: $fs-r*1.25;
        padding-right: $fs-r*1.5;
        padding-left: $fs-r*1.5;
        padding-bottom: $fs-r*1.25;
    }
}

figcaption {
    color: $color-grey-05;
    font-size: $fs-s;
    margin-top: 0.2em;
    display: block;

    @media screen and (min-width: 100em) {/* 1600px */
        font-size: $fs-r * 0.8;
    }
}
