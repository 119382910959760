
.sidebar-linklist {
    margin: 0;
    padding: 0;
}

.sidebar-linklist__item {
    border-bottom: 1px solid $color-grey-01;
    position: relative;
}

.sidebar-linklist__item__button {
    display: block;
    background: none;
    border: none;
    padding: 10px 18px 10px 1.5em;
    color: $color-grey-07;
    font-family: $primary-font;
}

.sidebar-linklist__item__button:before {
    content: ">";
    position: absolute;
    left: 0;
    color: $color-grey-07;
    top: calc(10px + 0.1em);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before,
.sidebar-linklist__item:hover .sidebar-linklist__item__button:before {
    left: .5em;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sidebar-linklist__item__link {
    position: relative;
    text-decoration: none;
    display: inline-block;
    padding: .25em 0;
    color: $color-grey-07;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: calc(50% - 5px);
        left: calc(100% + 7px);
        border-left: 8px solid rgba($color-01, 0);
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        transition: all $link-transition;
    }

    &:hover,
    &:focus {
        color: $color-grey-07;
        text-decoration: none;
        margin-left: 8px;

        &:after {
            border-left-color: $color-01;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        opacity: 0.75;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}

.sidebar-linklist__item__sublist {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-linklist__item__sublist__item {
    list-style: none;
}

.sidebar-linklist__item__sublist__item__link:before {
    content: "\2013\00a0";
    color: $color-grey-07;
}

.sidebar-linklist__item__sublist__item__link {
    display: block;
    text-decoration: none;
    padding: .25em .25em .25em 1.5em;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    color: $color-grey-07;
}

.sidebar-linklist__item__sublist__item__link:hover,
.sidebar-linklist__item__sublist__item__link:focus,
.sidebar-linklist__item__sublist__item__link:active {
    background: rgba(0,0,0,.05);
    color: $color-black;
}
