
.hero-banner {
    position: relative;
    background-color:$color-black;
    color: $color-white;
    padding-top: 60px;
    padding-bottom: 60px;

    @media screen and (min-width: 36em) { /* 576px */
        padding-top: 0;
        padding-bottom: calc(56.25% + 6.40625vw);
    }

    /* BP medium */
    @media screen and (min-width: 62em) { /* 992px */
        padding-bottom: calc(43.25% + 6.40625vw);
    }

    &__img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 25%;
        right: 0;
        background-color:$color-black;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center left;
        z-index: 0;

        @media screen and (min-width: 36em) { /* 576px */
            left: 33%;
        }

        @media screen and (min-width: 48em) { /* 768px */
            left: 40%;
        }
    }

    &__text {
        position: relative;
        z-index: 1;
        margin-left: 6vw;
        margin-right: 6vw;

        @media screen and (min-width: 36em) { /* 576px */
            position: absolute;
            top: 50%;
            left: 6vw;
            right: 6vw;
            transform: translateY(-50%);
            margin-left: 0;
            margin-right: 0;
        }
        @media screen and (min-width: 48em) { /* 768px */
            left: 4vw;
            right: 4vw;
        }
        @media screen and (min-width: 62em) { /* 992px */
            right: 50%;
        }
        @media screen and (min-width: 75em) { /* 1200px */
            padding-left: calc(92vw * 0.083333333);
        }
        @media screen and (min-width: 87.5em) {/* 1400px */
            /* 1280px / 2 */
            left: calc(50vw - 640px);
            padding-left: calc(1280px * 0.083333333);
        }
        @media screen and (min-width: 100em) {/* 1600px */
            /* 1392px / 2 */
            left: calc(50vw - 696px);
            padding-left: calc(1392px * 0.083333333);
        }
    }

    &--w-text-image {
        .hero-banner__cta {
            padding-top: 48px;
        }
    }

    &__subtitle {
        font-size: 2.4rem;
    }

    &__heading {
        line-height: 1.33;
        margin-top: 24px;
        @include line-deco-below();
        padding-bottom: 24px;
        margin-bottom: 0;
    }

    &__description {
        margin-top: 40px;
        font-size: $fs-r;
        border-left: 1px solid $color-white;
        padding-left: 24px;
    }

    &__cta {
        margin-top: 0;
    }

}
