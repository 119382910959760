.tinymce-media-iframe,
.widget-Video {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    clear: both;
}

.tinymce-media-iframe iframe,
.widget-Video iframe,
.widget-Video object,
.widget-Video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.tinymce-media-iframe {
    margin: 2rem 0;
}

iframe {
    border: 0;
}