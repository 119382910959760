
.button {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    white-space: normal;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: $color-white;
    background-color: transparent;
    text-decoration: none;
    border-radius: 0;
    border: 3px solid $color-00;
    outline: none;
    opacity: 1;
    position: relative;
    font-family: $primary-font;
    line-height: 1;
    font-weight: $fw-02;
    font-size: $fs-r;
    padding: 14px 40px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.05);
    z-index: 1;

    @media screen and (min-width: 100em) {
        font-size: $fs-r;
    }
}

.button:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $color-00;
    transition: all 200ms ease-in-out;
    z-index: -1;
}

.button:hover,
.button:focus,
.button:active {
    background-color: transparent;
    border-color: $color-00;
    color: $color-00;
    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
}

.button:hover:after,
.button:focus:after,
.button:active:after {
    right: 100%;
}

/* Button on reverse */
.reverse-text .button {
    color:#fff;
    background-color: $color-00;
}

.reverse-text .button:hover,
.reverse-text .button:focus,
.reverse-text .button:active {
    background-color: darken($color-00, 10%);
    color: $color-white;
}


/* - Button icons - */
.button__icon {
    display: inline-block;
    vertical-align: top;
    width: $spacing--medium;
    height: $spacing--medium;
}

.button__icon--right {
    margin-left: $spacing--xsmall;
    margin-right: -($spacing--xxsmall);
}

.button__icon--left {
    margin-right: $spacing--xsmall;
    margin-left: -($spacing--xxsmall);
}

.button__icon svg {
    width: 16px;
    height: 16px;
}

.button__icon svg path {
    fill: $color-white;
    transition: fill 250ms ease-in-out;
}

.button__icon--primary svg path {
    fill: $color-primary;
}

.button__icon--00 svg path {
    fill: $color-00;
}

.button:hover .button__icon--light-purple svg path {
    fill: $color-white;
}


/* - Button styles - */


.reverse-text .button:hover,
.reverse-text .button:focus,
.reverse-text .button:active {
    opacity: 1;
}

.button[type="submit"] {
    background-color: $color-00;
    border-color: $color-00;
    color: $color-white;
    transition: all 200ms ease-in-out;
}

.button[type="submit"]:hover,
.button[type="submit"]:focus {
    background-color: $color-01;
    border-color: $color-01;
}

.button[type="submit"]:after {
    content: none;
}


/* 00 */
.button--00,
.reverse-text .button--00 {
    border-color: $color-00;
    color: $color-grey-08;
}

.button--00:after {
    background-color: $color-00;
}


.button--00:hover,
.button--00:focus,
.reverse-text .button--00:hover,
.reverse-text .button--00:focus {
    border-color: $color-00;
    color: $color-grey-08;
}


/* 01 - dark blue */
.button--01,
.reverse-text .button--01 {
    border-color: $color-01;
    color: $color-white;
}

.button--01:after {
    background-color: $color-01;
}

.button--01:hover,
.button--01:focus,
.reverse-text .button--01:hover,
.reverse-text .button--01:focus {
    border-color: $color-01;
    color: $color-grey-08;
}


/* 02 - light blue */
.button--02,
.reverse-text .button--02 {
    border-color: $color-02;
    color: $color-white;
}

.button--02:after {
    background-color: $color-02;
}

.button--02:hover,
.button--02:focus,
.reverse-text .button--02:hover,
.reverse-text .button--02:focus {
    border-color: $color-02;
    color: $color-grey-08;
}


.button-grey,
.reverse-text .button-grey {
    border-color: $color-grey-06;
    color: $color-white;
}

.button-grey:after {
    background-color: $color-grey-06;
}

.button-grey:hover,
.button-grey:focus,
.reverse-text .button-grey:hover,
.reverse-text .button-grey:focus {
    border-color: $color-grey-06;
    color: $color-grey-06;
}

/* Outline White */
.button--outline-white,
.reverse-text .button--outline-white {
    border: 2px solid $color-white;
    color: $color-white;
    z-index: 1;
}

.button--outline-white:after,
.reverse-text .button--outline-white:after {
    content: "";
    left: 100%;
    right: 0;
    background-color: $color-white;
}

.button--outline-white:hover,
.button--outline-white:focus,
.reverse-text .button--outline-white:hover,
.reverse-text .button--outline-white:focus {
    border-color: $color-white;
    color: $color-grey-09;
}

.button--outline-white:hover:after,
.button--outline-white:focus:after,
.reverse-text .button--outline-white:hover:after,
.reverse-text .button--outline-white:focus:after {
    left: 0;
    right: 0;
}

/* Red Warning Button */
.button--warning {
    background-color: $color-utility-warning;
    color: $color-white;
}

.button--warning:hover,
.button--warning:focus {
    background-color: darken($color-utility-warning, 10%);
    color: $color-white;
}

/* Grey */
.button--neutral {
    background-color: $color-grey-02;
    color: $color-grey-08;
}

.button--neutral:hover,
.button--neutral:focus {
    background-color: $color-grey-03;
    color: $color-grey-08;
}

.button--neutral svg path{
    fill: $color-grey-08;
}

/* Green */
.button--positive {
    background-color: $color-utility-positive-subtle;
    color: $color-white;
}

.button--positive:hover,
.button--positive:focus {
    background-color: $color-utility-positive;
    color: $color-white;
}

/* Block */
.button--block {
    display: block;
    width: 100%;
}

/* Disabled/unavailable button style */
.button[disabled],
.button--disabled,
.button--disabled:hover,
.button--disabled:focus,
.button--disabled:active {
    border: none;
    opacity: 0.4;
    cursor: default;
    -webkit-box-shadow: none;
            box-shadow: none;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
    padding: 0;
    border: 0;
}


/* Small */
.button-small,
.button--small {
    line-height: 1;
    padding: $spacing $spacing*1.5 $spacing;

    @media screen and (min-width: 48em) { /* 768px */
        padding: $spacing $spacing*1.5 $spacing;
    }
}

/* BP medium */
@media only screen and (min-width: 62em) { /* 992px */
    /* Large */
    .button-large,
    .button--large {
        padding: 14px 48px;
    }

    .button--large .button__icon {
        width: $spacing--medium*1.5;
        height: $spacing--medium*1.5;
    }

    .button--large .button__icon svg {
        width: $spacing--medium*1.5;
        height: $spacing--medium*1.5;
    }

    .button--large .button__icon--right {
        margin-left: $spacing--xsmall;
        margin-right: -($spacing);
    }

    .button--large .button__icon--left {
        margin-right: $spacing--xsmall;
        margin-left: -($spacing);
    }

}

.btn-cta-spacing {
    margin-top: $fs-r*2;
}
