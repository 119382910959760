

.feature-box-wrapper {

    & > *:last-child {
        margin-bottom: 0;
    }


    @media only screen and (min-width: 48em) { /* 768px */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-right: -12px;
        margin-left: -12px;

        @supports(display: grid) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            grid-gap: 24px;
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media only screen and (min-width: 62em) { /* 992px */
        margin-right: -20px;
        margin-left: -20px;

        @supports(display: grid) {
            margin-left: 0;
            margin-right: 0;
            grid-gap: 40px;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        @supports(display: grid) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}


.feature-box {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 6vw;

    @media screen and (min-width: 48em) { /* 768px */
        box-shadow: 0 0 20px rgba(0,0,0,0.15);
        flex: 0 1 50%;
        max-width: calc(50% - 24px);
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 24px;

        @supports(display: grid) {
            flex: 0 1 100%;
            max-width: 100%;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;

            &--0 {
                grid-column: 1;
                grid-row: 1 / 3;
            }

            &--1 {
                grid-column: 2;
                grid-row: 2 / 4;
            }

            &--2 {
                grid-column: 1;
                grid-row: 3 / 5;
            }
        }

    }

    @media only screen and (min-width: 62em) { /* 992px */
        max-width: calc(50% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 40px;

        @supports(display: grid) {
            flex: 0 1 100%;
            max-width: 100%;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        flex: 0 1 33.33%;
        max-width: calc(33.33% - 40px);

        margin-bottom: 0;

        @supports(display: grid) {
            flex: 0 1 100%;
            max-width: 100%;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;

            &--0 {
                grid-column: 1;
                grid-row: 1 / 5;
            }

            &--1 {
                grid-column: 2;
                grid-row: 1 / 5;
            }

            &--2 {
                grid-column: 3;
                grid-row: 1 / 5;
            }
        }
    }
}

.feature-box__inner {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;

    @media screen and (min-width: 22.5em) {/* 360px */
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 32px;
        padding-top: 32px;
    }

    @media screen and (min-width: 48em) { /* 768px */
        display: flex;
        flex-direction: column;
    }

    @media only screen and (min-width: 62em) { /* 992px */
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
    }
}

.feature-box__heading-row {
    width: 100%;
    margin-bottom: 1.5em;
}

.feature-box__heading {
    font-size: $fs-r*1.2;
    @include line-deco-below;
    padding-bottom: 16px;
    margin-bottom: 24px;

    @media only screen and (min-width: 62em) { /* 992px */
        font-size: $fs-r*1.375;
    }
}

.feature-box__heading-row .feature-box__heading {
    margin-bottom: 0;
}

.feature-box__content {
    margin-bottom: 1.25em;
}

.feature-box__button {
    margin-top: auto;
    margin-bottom: 0;

    .button {
        width: 100%;

        @media only screen and (min-width: 62em) { /* 992px */
            width: auto;
        }
    }
}
