
.left,
.center,
.right,
.alignleft,
.alignright {
    margin: 30px auto;
    display: block;
    clear: both;
}


/* BP Smaller */
@media screen and (min-width: 36em) { /* 576px */
    .left,
    .alignleft {
        margin: 0 30px 30px 0;
        float: left;
    }

    .right,
    .alignright {
        margin: 0 0 30px 30px;
        float: right;
    }
}
