.field-input .textbox,
.field-input select,
.field-element--fileselector .fs-preview-wrapper {
    display: block;
    width: 100%;
    outline: none;
    line-height: normal;
	padding: $form-spacing*1.25 $form-spacing*2 $form-spacing*1.25;
    -webkit-appearance: none;
    line-height: 1.4;
}
