
a {
    color: $color-00;
    word-break: break-word;
    transition: all $link-transition;
}

a:hover,
a:active,
a:focus {
    color: darken($color-00, 20%);
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
    outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
    outline: 0;
}

/* Include file type and size in document links */
a.document::after {
    content: " (" attr(data-ext) " " attr(data-size) ")";
}

// Invisible
.link-invisible {
    color: inherit;
    text-decoration: none;
}

.link-inner {
    text-decoration: none;

    &__inner {
        text-decoration: underline;
    }
}

.txt-link--arrow {
    position: relative;
    font-weight: $fw-03;
    font-size: $fs-r*1.5;
    line-height: 1.5;
    padding-right: 72px;
    color: inherit;
    text-decoration: none;

    @media screen and (min-width: 48em) { /* 768px */
        font-size: $fs-r*2;
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(100% - 56px);
        width: 56px;
        height: 5px;
        transform: translateY(-50%);
        transition: all $link-transition;
        background-color: $color-grey-07;
    }

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 16px;
        height: 16px;
        transform: translateY(-50%) rotate(45deg);
        transition: all $link-transition;
        border-top: 5px solid $color-grey-07;
        border-right: 5px solid $color-grey-07;
    }

    &:hover,
    &:focus {
        color: inherit;

        &:before {
            width: 72px;
        }
        &:after {
            transform: translate(16px, -50%) rotate(45deg);
        }
    }
}

.reverse-text .txt-link--arrow:before {
    background-color: $color-white;
}

.reverse-text .txt-link--arrow:after {
    border-top-color: $color-white;
    border-right-color: $color-white;
}
