.post-gallery-item {
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0,0,0,0.15);
    margin-bottom: 40px;

    @media screen and (min-width: 48em) { /* 768px */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__content {
        padding-top: 32px;
        padding-bottom: 40px;
        padding-left: 40px;
        padding-right: 40px;

        @media screen and (min-width: 48em) { /* 768px */
            display: flex;
            flex-direction: column;
            padding-top: 40px;
        }
    }

    &__heading {
        font-size: $fs-r*1.2;
        line-height: 1.33;


        @media screen and (min-width: 30em) {/* 480px */
            font-size: $fs-r*1.33;
        }


        @media screen and (min-width: 48em) { /* 768px */
            font-size: $fs-r*1.66;
        }

        @media screen and (min-width: 100em) {/* 1600px */
            font-size: $fs-r*2;
        }
    }

    &__categories {
        list-style-type: none;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0.2em;
        @include subtitle;

            &__item {
            display: inline-block;

            &:after {
                content: "\2002";
            }
        }
    }

    &__text {
        line-height: 1.5;
    }

    &__cta {
        margin-top: auto;
        margin-bottom: 0;
    }

    &__media {
        width: 100%;
        padding-bottom: 75%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}


.post-gallery-item--full {
    .post-gallery-item {

        &__content {
            @media screen and (min-width: 48em) { /* 768px */
                flex: 0 0 auto;
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        &__media {
            @media screen and (min-width: 48em) { /* 768px */
                padding-bottom: 0;
                flex: 0 0 auto;
                flex-basis: 50%;
                max-width: 50%;
            }
        }
    }
}



.post-gallery-item--condensed {
    .post-gallery-item {
        &__media {
            @media screen and (min-width: 48em) { /* 768px */
                order: -1;
            }

            @media screen and (min-width: 48em) { /* 768px */
                flex: 0 0 auto;
                flex-basis: 50%;
                max-width: 50%;
                padding-bottom: 0;
            }

            @media screen and (min-width: 75em) { /* 1200px */
                flex-basis: 100%;
                max-width: 100%;
                padding-bottom: 56.25%;
                margin-top: auto;
            }
        }

        &__content {
            @media screen and (min-width: 48em) { /* 768px */
                flex: 0 0 auto;
                flex-basis: 50%;
                max-width: 50%;
            }
            @media screen and (min-width: 75em) { /* 1200px */
                flex-basis: 100%;
                max-width: 100%;
                padding-top: 28px;
            }
        }

        &__text {
            @media screen and (min-width: 75em) { /* 1200px */
                display: none;
            }
        }
    }
}

.post-gallery-item--video {
    .post-gallery-item {
        &__media {
            padding-bottom: 0;

            @media screen and (min-width: 48em) { /* 768px */
                flex-basis: 100%;
                max-width: 100%;
            }

            @media screen and (min-width: 62em) { /* 992px */
                flex: 0 0 auto;
                flex-basis: 50%;
                max-width: 50%;
            }

            @media screen and (min-width: 75em) { /* 1200px */
                order: 1;
                flex-basis: 100%;
                max-width: 100%;
            }
        }

        &__heading {
            margin-bottom: 0;
        }

        &__content {
            @media screen and (min-width: 48em) { /* 768px */
                flex-basis: 100%;
                max-width: 100%;
            }

            @media screen and (min-width: 62em) { /* 992px */
                flex: 0 0 auto;
                flex-basis: 50%;
                max-width: 50%;
            }

            @media screen and (min-width: 75em) { /* 1200px */
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }
}
