.breadcrumb {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: $fs-r;
    color: #848484;
    line-height: 1.2;

    @media screen and (min-width: 62em) { /* 992px */
        line-height: 1;
    }

    @media screen and (min-width: 100em) {/* 1600px */
        font-size: $fs-r;
    }

    > li {
        display: inline;
        color: $color-00;

        &:before {
            content: "\2003\2002";
        }

        &:first-child:before {
            content: none;
        }
    }

    a {
        text-decoration: none;
        color: $color-grey-07;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}


@media screen and (max-width: 47.9375em) { /* 767px */
    .breadcrumb {
        margin-top: 0;
        margin-bottom: 10px;
        line-height: 1.4;
    }
    .breadcrumb > li {
        display: none;
        margin-left: 0;
    }
    .breadcrumb > li:first-child,
    .breadcrumb > li:nth-last-child(2) {
        display: inline-block;
    }
    .breadcrumb > li:nth-last-child(2):before{
        background: none;
        content: '\003c\00a0';
        position: unset;
        font-weight: $fw-03;
        position: relative;
        left: -1px;
        margin-left: 0px;
        top: 0;
    }
}

@media screen and (max-width: 61.25em) { /* 980px */
    /* code for the blog posts because the breadcrumbs are nonsensical */
    .blog-post-controller .breadcrumb > li {
        display: none;
        margin-left: 0;
    }
    .blog-post-controller .breadcrumb > li:nth-last-child(5) {
        display: inline-block;
    }
    .blog-post-controller .breadcrumb > li:nth-last-child(2) {
        display: none;
    }
    .blog-post-controller .breadcrumb > li:nth-last-child(5):before {
        background: none;
        content: '\003c\00a0';
        position: unset;
        font-weight: $fw-03;
        position: relative;
        left: -1px;
        margin-left: 0px;
        top: 0;
    }
}
