.article-image {

}

.article-image__row {
    width: 100%;
}

.article-image__image {
    width: 100%;
    padding-bottom: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 40px;
}

.article-image__content__text > *:last-child {
    margin-bottom: 0;
}

.article-image__image + .article-image__content {

}


@media screen and (min-width: 48em) { /* 768px */
    .article-image--left,
    .article-image--right {
        position: relative;

        .article-image__row {
            display: flex;
            flex: 0 1 auto;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .article-image__content {
            flex: 0 0 auto;
            flex-basis: 66.66%;
            max-width: 66.66%;
        }

        .article-image__image {
            position: absolute;
            top: 0;
            bottom: 0;
            width: auto;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .article-image--left {
        .article-image__content {
            margin-left: 33.33%;
            margin-right: 0;
            padding-left: 40px;
        }

        .article-image__image {
            left: 0;
            right: 66.66%;
            background-position: center right;
        }
    }

    .article-image--right {
        .article-image__content {
            margin-left: 0;
            margin-right: 33.33%;
            padding-right: 40px;
        }

        .article-image__image {
            left: 66.66%;
            right: 0;
            background-position: center left;
        }
    }


}


.article-image--left,
.article-image--right {
    .article-image__content {
        @media screen and (min-width: 62em) { /* 992px */
            flex-basis: 50%;
            max-width: 50%;
        }
    }
}

.article-image--left {
    .article-image__content {
        @media screen and (min-width: 62em) { /* 992px */
            margin-left: 50%;
            margin-right: 0;
            padding-left: 6.249999974%;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            padding-right: calc(92vw * 0.083333333);
        }

        /* BP xlarge */
        @media screen and (min-width: 87.5em) {/* 1400px */
            padding-right: calc(1280px * 0.083333333);
        }

        /* BP xxlarge */
        @media screen and (min-width: 100em) {/* 1600px */
            padding-right: calc(1392px * 0.083333333);
        }
    }

    .article-image__image {
        @media screen and (min-width: 62em) { /* 992px */
            left: 0;
            right: 50%;
        }
    }
}

.article-image--right {
    .article-image__content {
        @media screen and (min-width: 62em) { /* 992px */
            margin-left: 0;
            margin-right: 50%;
            padding-right: 6.249999974%;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            padding-left: calc(92vw * 0.083333333);
        }

        /* BP xlarge */
        @media screen and (min-width: 87.5em) {/* 1400px */
            padding-left: calc(1280px * 0.083333333);
        }

        /* BP xxlarge */
        @media screen and (min-width: 100em) {/* 1600px */
            padding-left: calc(1392px * 0.083333333);
        }
    }

    .article-image__image {
        @media screen and (min-width: 62em) { /* 992px */
            left: 50%;
            right: 0;
        }
    }
}
