

$primary-font: 'Montserrat', Helvetica, Arial, sans-serif;

$fw-01: 300;

$fw-02: 400;

$fw-03: 800;

$fs-s: 1.2rem;

$fs-r: 1.6rem;
