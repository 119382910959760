.widget-title {
    @include line-deco-above;
    padding-top: 20px;

    &:before {
        background: $color-01;
    }
}

.sidebar .widget + .widget {
    margin-top: 40px;

    @media screen and (min-width: 87.5em) {/* 1400px */
        margin-top: 64px;
    }
}
