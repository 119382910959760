
@media screen and (min-width: 64em) { /* 1024px */
    .no-js .home-cta-item--1 {
        opacity: 1;
        transform: translate(0 0);
    }

    .home-cta-item--1,
    .js .home-cta-item--1 {
        opacity: 0;
        -webkit-transform: translate(-50px, 50px);
        transform: translate(-50px, 50px);
    }

    .home-cta-item--1.animate-in,
    .js .home-cta-item--1.animate-in {
        animation: fadeInLeft 500ms ease-in-out;
        animation-fill-mode: forwards;
    }

    /* Animate content in */
    .no-js .home-cta-item--2 {
        opacity: 1;
        transform: translate(0 0);
    }

    .home-cta-item--2,
    .js .home-cta-item--2 {
        opacity: 0;
        -webkit-transform: translate(50px, 50px);
        transform: translate(50px, 50px);
    }

    .home-cta-item--2.animate-in,
    .js .home-cta-item--2.animate-in {
        animation: fadeInRight 500ms ease-in-out;
        animation-fill-mode: forwards;
    }
}
