blockquote {
    margin-top: $fs-r;
    margin-bottom: $fs-r;
    margin-left: $fs-r*2;
    margin-right: 0;
    padding-top: $fs-r*1.25;
    padding-right: $fs-r*1.5;
    padding-left: $fs-r*3.5;
    padding-bottom: $fs-r*1.25;
    background: $color-grey-01;
    font-style: italic;
    position: relative;
    clear: both;

    @media screen and (min-width: 100em) {/* 1600px */
        margin-top: $fs-r;
        margin-bottom: $fs-r;
        margin-left: $fs-r*2;
        padding-top: $fs-r*1.25;
        padding-right: $fs-r*1.5;
        padding-left: $fs-r*4;
        padding-bottom: $fs-r*1.25;
    }
}

blockquote *:first-child {
    margin-top: 0;
}

blockquote *:last-child {
    margin-bottom: 0;
}

blockquote:before,
blockquote:after {
    content: '';
    position: absolute;
    display: block;
    line-height: 10px;
}

blockquote:before {
    left: -32px;
    top: calc(50% - 32px);
    height: 64px;
    width: 64px;
    border-radius: 32px;
    background-color: $color-04;
    background: linear-gradient(60deg, rgba(195,247,58,1) 0%, rgba(149,224,108,1) 100%);

    @media screen and (min-width: 100em) {/* 1600px */
        left: -36px;
        top: calc(50% - 36px);
        height: 72px;
        width: 72px;
        border-radius: 36px;
    }
}

blockquote:after {
    content: '\201C\201D';
    left: -3.8rem;
    top: calc(50% + 2rem);
    color: $color-grey-08;
    font-style: italic;
    font-size: 8rem;

    @media screen and (min-width: 100em) {/* 1600px */
        left: -4.8rem;
        top: calc(50% + 2.8rem);
        font-size: 10rem;
    }
}

@media only screen and (min-width: 62em) { /* 992px */
    .blockquote--left {
        float: left;
        width: 45%;
        margin-left: 0px;
        margin-right: 20px;
        clear: right;
    }

    .blockquote--right {
        float: right;
        width: 45%;
        margin-left: 20px;
        margin-right: 0px;
        clear: left;
    }
}
