.section-header {
    margin-bottom: 40px;

    &.section-header--center-md2 {
        text-align: center;
    }

    @media screen and (min-width: 64em) { /* 1024px */
        position: relative;
        padding-right: 232px;

        &.section-header--center-md2 {
            text-align: left;
        }
    }

    &__cta {
        display: none;

        @media screen and (min-width: 64em) { /* 1024px */
            display: block;
            position: absolute;
            right: 0;
            top: calc(50% - 20px);
        }
    }
}

.section-footer {
    display: block;

    &.section-footer--center-md2 {
        text-align: center;
    }

    @media screen and (min-width: 64em) { /* 1024px */
        display: none;
    }
}
