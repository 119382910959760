

// Brand colours

$color-primary: 			 #F29200; // NAVY - legacy

$color-00:                   #F29200; // brand orange

$color-01: 				     #6E778A; // steel grey

$color-02:                   #122932; // navy

$color-03:                   #090A0E; // indigo

$color-04:                   #C3F73A; // lime green


// Neutral Colours

$color-white:						#ffffff;

$color-grey-01:                     #eeeeee;

$color-grey-02:						#D9D9D9;

$color-grey-03:						#b8b8b8;

$color-grey-04:						#9c9c9c;

$color-grey-05:						#767676;

$color-grey-06:						#636363;

$color-grey-07:						#47484B; /* branded */

$color-grey-08:						#2b2b2b;

$color-grey-09:						#090A0E;

$color-black:						#000000;


// Utility Colours

$color-utility-positive:			#0E6F22;

$color-utility-positive-subtle:		#26883A;

$color-utility-alert:				#A72D00;

$color-utility-alert-subtle:		#CD4C1D;

$color-utility-warning:				#9E0E0B;

$color-utility-warning-subtle:		#C32C2A;

$color-utility-neutral:				#0192d0;

$color-utility-neutral-subtle:		#d3f2ff;
