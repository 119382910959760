.field-element--datepicker .field-input,
.field-element--daterangepicker .field-input {
    position: relative;
}

.field-element--datepicker .field-input:before,
.field-element--daterangepicker .field-input:before {
    content: "\00a0";
    display: block;
    position: absolute;
    left: $form-spacing/4;
    top: calc(50% - 12px);
    width: $form-spacing*5;
    background-image: url(../images/icon-system/icon_form_datepicker.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border-right: 1px solid $color-grey-04;
}
