
.bg-white {
    background-color: $color-white;
}

.bg-lightgrey {
    background-color: $color-grey-01;
}

.bg-primary {
    background-color: $color-primary;
}

.bg-00 {
    background-color: $color-00;
}

.bg-01 {
    background-color: $color-01;
}

.bg-02 {
    background-color: $color-02;
}

.bg-03 {
    background-color: $color-03;
}

.bg-04 {
    background-color: $color-04;
}

.bg-black {
    background-color: $color-black;
}
