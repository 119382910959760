.post-sidebar-related-list {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.5;
}

.post-sidebar-related-list__item {
    position: relative;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    display: block;
}

.post-sidebar-related-list__item a {
    position: relative;
    text-decoration: none;
    display: inline-block;
    padding: 0;
    color: $color-grey-07;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: calc(50% - 5px);
        left: calc(100% + 7px);
        border-left: 8px solid rgba($color-01, 0);
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        transition: all $link-transition;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        margin-left: 12px;

        &:after {
            border-left-color: $color-01;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        opacity: 0.75;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}


/* On */
.post-sidebar-related-list__item.on > a {
    padding-left: 0;
    color: $color-00;

    &:after {
        border-left-color: $color-00;
    }

    &:hover,
    &:focus {
        margin-left: 0;
        color: $color-grey-07;
        margin-left: 12px;

        &:after {
            border-left-color: $color-01;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        opacity: 1;
    }
}

