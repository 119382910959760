.js .expando {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.js .expando p:last-of-type {
    margin-bottom: 0;
}

/* - Open button - */
.expando-open {
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: none;
    padding: 6px 0 3px 40px;
    font-family: $primary-font;
    color: $color-01;
    position: relative;
    cursor: pointer;
    display: block;
    margin: 0 0 12px;
    font-size: inherit;
    line-height: inherit;
    transition: color $link-transition;
}

.expando-open__icon {
    position: absolute;
    top: 50%;
    left: 0;
    background-color: $color-grey-01;
    width: 26px;
    height: 26px;
    transform: translate(0, -50%);
}

.expando-open__icon:before,
.expando-open__icon:after {
    content: " ";
    width: 14px;
    height: 2px;
    background-color: $color-grey-06;
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: all $link-transition;
}

.expando-open__icon:after {
    transform: rotate(90deg);
}

/* Hover */
.expando-open:hover,
.expando-open:focus {
    color: darken($color-01, 10%);
    text-decoration: underline;
}

.expando-open:hover .expando-open__icon {
    background-color: $color-grey-02;
}

.expando-open:hover .expando-open__icon:before,
.expando-open:hover .expando-open__icon:after {
    background-color: $color-grey-09;
}

/* - Close button - */
.expando__close {
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: none;
    text-indent: -9999px;
    padding: 0;
    height: 26px;
    width: 26px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}
.expando__close:before,
.expando__close:after {
    content: " ";
    width: 14px;
    height: 2px;
    background-color: $color-grey-06;
    display: block;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: transform 200ms ease;
}

.expando__close:before {
    transform: rotate(-45deg);
}

.expando__close:after {
    transform: rotate(45deg);
}

/* Hover */
.expando__close:hover:before,
.expando__close:hover:after {
    background-color: $color-grey-09;
}


/* - Expanded - */
.expando.expando--expanded {
    clip: auto;
    height: auto;
    margin: 20px 0 30px;
    overflow: visible;
    position: relative;
    width: auto;
    padding: 25px 45px;
    background-color: $color-grey-01;
    animation: fadeInDown 200ms ease;
    animation-fill-mode: forwards;
}

.expando-open--expanded .expando-open__icon:before {
    transform: rotate(-45deg);
}

.expando-open--expanded .expando-open__icon:after {
    transform: rotate(45deg);
}
