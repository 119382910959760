

.promo-wrapper {

    & > *:last-child {
        margin-bottom: 0;
    }


    @media only screen and (min-width: 48em) { /* 768px */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-right: -12px;
        margin-left: -12px;

        @supports(display: grid) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            grid-gap: 24px;
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media only screen and (min-width: 62em) { /* 992px */
        margin-right: -20px;
        margin-left: -20px;

        @supports(display: grid) {
            margin-left: 0;
            margin-right: 0;
            grid-gap: 40px;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        @supports(display: grid) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &.promo-wrapper--w-brochure {
        @media screen and (min-width: 75em) { /* 1200px */
            @supports(display: grid) {
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 20px 1fr 20px 120px;
            }
        }
    }
}


.promo {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 6vw;

    @media screen and (min-width: 22.5em) {/* 360px */
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 32px;
        padding-top: 48px;
    }

    @media screen and (min-width: 48em) { /* 768px */
        box-shadow: 0 0 20px rgba(0,0,0,0.15);
        flex: 0 1 50%;
        align-items: center;
        max-width: calc(50% - 24px);
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 24px;

        @supports(display: grid) {
            flex: 0 1 100%;
            max-width: 100%;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;

            &--0 {
                grid-column: 1;
                grid-row: 1 / 3;
            }

            &--1 {
                grid-column: 2;
                grid-row: 2 / 4;
            }

            &--2 {
                grid-column: 1;
                grid-row: 3 / 5;
            }
        }

    }

    @media only screen and (min-width: 62em) { /* 992px */
        max-width: calc(50% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 40px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 40px;
        padding-top: 60px;

        @supports(display: grid) {
            flex: 0 1 100%;
            max-width: 100%;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        flex: 0 1 33.33%;
        max-width: calc(33.33% - 40px);

        margin-bottom: 0;

        @supports(display: grid) {
            flex: 0 1 100%;
            max-width: 100%;
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 0;

            &--0 {
                grid-column: 1;
                grid-row: 1 / 5;
            }

            &--1 {
                grid-column: 2;
                grid-row: 1 / 5;
            }

            &--2 {
                grid-column: 3;
                grid-row: 1 / 5;
            }

            &--brochure {
                grid-column: 2;
                grid-row:  4 / 5;
            }
        }

        &:nth-child(even) {
            margin-top: 60px;
        }

        &:nth-child(odd) {
            margin-bottom: 60px;
        }
    }
}

.promo-wrapper--w-brochure {
    .promo {
        @media screen and (min-width: 75em) { /* 1200px */
            @supports(display: grid) {
                flex: 0 1 100%;
                max-width: 100%;
                margin-bottom: 0;
                margin-left: 0;
                margin-right: 0;

                &--0 {
                    grid-column: 1;
                    grid-row: 1 / 3;
                }

                &--1 {
                    grid-column: 2;
                    grid-row: 2 / 4;
                }

                &--2 {
                    grid-column: 3;
                    grid-row: 1 / 3;
                }

                &--brochure {
                    grid-column: 3;
                    grid-row:  3 / 5;
                }
            }
        }
    }
}

.promo__heading-row {
    width: 100%;
    margin-bottom: 1.5em;
}

.promo__heading {
    font-size: $fs-r*1.2;
    margin-bottom: 1.5em;
    @include line-deco-above;

    @media only screen and (min-width: 62em) { /* 992px */
        font-size: $fs-r*1.375;
    }

    &--w-icon {
        margin-bottom: 0;

        @media screen and (min-width: 62em) { /* 992px */
            line-height: 1.75;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            line-height: 1.33;
        }

        @media screen and (min-width: 87.5em) {/* 1400px */
            line-height: 1.75;
        }
    }

    &--condensed {
        letter-spacing: -0.02em;


        // Meticulous line breaks for og design
        @media screen and (min-width: 48em) { /* 768px */
            padding-right: 24px;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            padding-right: 32px;
        }

        @media screen and (min-width: 87.5em) {/* 1400px */
            padding-right: 16px;
        }

        @media screen and (min-width: 100em) {/* 1600px */
            padding-right: 0;
        }
    }

    &--padded {
        // Meticulous line breaks for og design
        padding-right: 32px;

        @media screen and (min-width: 62em) { /* 992px */
            padding-right: 48px;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            padding-right: 32px;
        }
    }
}

.promo__heading-row .promo__heading {
    margin-bottom: 0;
}

.promo__icon-wrapper {
    flex: 0 1 64px;
    max-width: 64px;
    position: relative;
    z-index: 2;
    height: 64px;
    width: 64px;
    margin-left: auto;
    margin-right: 0;

    @media screen and (min-width: 62em) { /* 992px */
        flex: 0 1 90px;
        max-width: 90px;
        height: 90px;
        width: 90px;
    }
}

.promo__icon {
    position: relative;
    z-index: 2;
    background-color: #fff;
    height: 64px;
    width: 64px;
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 11px 8px -4px rgba(0,0,0,0.2);

    @media screen and (min-width: 62em) { /* 992px */
        height: 90px;
        width: 90px;
    }
}

.promo__decorative-loop {
    content: "";
    position: absolute;
    z-index: 0;
    top: -14px;
    right: -14px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    border: 6px solid $color-01;

    @media screen and (min-width: 62em) { /* 992px */
        width: 90px;
        height: 90px;
        top: -20px;
        right: -20px;
    }
}

.promo__content {
    margin-bottom: 1.25em;
}

.promo__button {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    @media only screen and (min-width: 62em) { /* 992px */
        width: auto;
        padding-left: 40px;
        padding-right: 40px;
    }
}

.promo.promo--brochure {
    text-decoration: none;
    color: inherit;
    padding-top: 48px;
    padding-bottom: 48px;
    transition: all $link-transition;
    margin-top: 6vw;

    &:hover {
        color: $color-black;
    }

    @media only screen and (min-width: 48em) { /* 768px */
        margin-top: 0;
    }

    .promo__heading {
        margin-bottom: 0;
    }

    .txt-link {
        position: relative;
        transition: all $link-transition;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            height: 1px;
            background-color: $color-grey-07;
            transition: all $link-transition;
        }

        &:hover {
            color: $color-primary;

            &:after {
                background-color: $color-primary;
            }
        }
    }
}
