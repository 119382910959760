
.container {
    width: 88%;
    margin: 0 auto;
}

/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .container {
        width: 92%;
    }
}

/* BP xlarge */
@media screen and (min-width: 87.5em) {/* 1400px */
    .container {
        max-width: 1280px;
    }
}

/* BP xxlarge */
@media screen and (min-width: 100em) {/* 1600px */
    .container {
        max-width: 1392px;
    }
}
