.widget-RelatedLinks {
    margin-bottom: 25px;
}

.widget-RelatedLinks ul {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.5;
}

.widget-RelatedLinks li {
    display: block;
}

.widget-RelatedLinks a {
    position: relative;
    text-decoration: none;
    display: inline-block;
    padding: 0;
    color: $color-grey-07;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: calc(50% - 5px);
        left: calc(100% + 7px);
        border-left: 8px solid rgba($color-01, 0);
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        transition: all $link-transition;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        margin-left: 12px;

        &:after {
            border-left-color: $color-01;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        opacity: 0.75;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}


/* - Depth 1 - */
.widget-RelatedLinks li.depth1 {
    position: relative;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
}
.widget-RelatedLinks li.depth1 > a {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}

/* On */
.widget-RelatedLinks li.depth1.on > a {
    padding-left: 0;
    color: $color-00;

    &:after {
        border-left-color: $color-00;
    }

    &:hover,
    &:focus {
        margin-left: 0;
        color: $color-grey-07;
        margin-left: 12px;

        &:after {
            border-left-color: $color-01;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        opacity: 1;
    }
}


/* - Depth 2 - */
.widget-RelatedLinks li.depth2 {
    font-size: 1.4rem;
}
.widget-RelatedLinks li.depth2 a:before {
    content: "- ";
}
.widget-RelatedLinks li.depth2 a {
    padding: 3px 0 3px $spacing--large;
}

/* On */
.widget-RelatedLinks li.depth2.on > a {
    color: $color-02;
    font-weight: bold;
}
