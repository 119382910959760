.swish {
    position: relative;
}

.swish:before,
.swish:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;
}

/* -------------------------
Inside
--------------------------- */

/* ---- Top ---- */
.swish-inside-t {
    padding-top: 5.5556vw;
}

.swish-inside-t:before {
    top: 0;
    height: 5.5556vw;
}


/* ---- Bottom ---- */
.swish-inside-b:after {
    bottom: 0;
    background-position: center top;
}

/* Inside Bottom - Banner */
.swish-inside-b--banner {
    /* inherit default padding */
}

.swish-inside-b--banner:after {
    height: 6.40625vw;
    background-image: url(../images/swish-banner.svg);
}

/* Inside Bottom - 01 */
.swish-inside-b--01 {
    padding-bottom: 7.65625vw;
}

.swish-inside-b--01:after {
    height: 7.65625vw;
    background-image: url(../images/swish-sb-01.svg);
}



/* ---- Inside Overlay ---- */
.swish-inside-overlay-b:after {
    padding-bottom: 0;
}

.swish-inside-overlay-t:before {
    padding-top: 0;
}

/* -------------------------
Outside
--------------------------- */

/* ---- Top ---- */
.swish-outside-t:before {
    bottom: 100%;
    background-position: center bottom;
}

// 04 is the banner inside swish - used as 04 on the panel page
.swish-outside-t--04 {
    margin-top: 6.40625vw;
}

.swish-outside-t--04:before,
.swish-outside-t--04:before {
    height: 6.40625vw;
    background-image: url(../images/swish-banner.svg);
}


/* Outside Top - 02 */
.swish-outside-t--02,
.swish-outside-t--05 {
    margin-top: 8.177083vw;
}

.swish-outside-t--02:before,
.swish-outside-t--05:before {
    height: 8.177083vw;
    background-image: url(../images/swish-st-02.svg);
}


/* Outside Top - 03 */
.swish-outside-t--03,
.swish-outside-t--06 {
    margin-top: 7.2395833vw;
}

.swish-outside-t--03:before,
.swish-outside-t--06:before {
    height: 7.2395833vw;
    background-image: url(../images/swish-st-03.svg);
}

/* Outside Top - Footer */
.swish-outside-t--footer {
    margin-top: 5.3125vw;
}

.swish-outside-t--footer:before {
    height: 5.3125vw;
    background-image: url(../images/swish-footer.svg);
}



/* ---- Bottom ---- */
.swish-outside-b:after {
    top: 100%;
    background-position: center top;
}

/* Outside Bottom - 01 */
.swish-outside-b--01,
.swish-outside-b--04 {
    margin-bottom: 7.65625vw;
}

.swish-outside-b--01:after,
.swish-outside-b--04:after {
    height: 7.65625vw;
    background-image: url(../images/swish-sb-01.svg);
}

/* Outside Bottom - 02 */
.swish-outside-b--02,
.swish-outside-b--05 {
    margin-bottom: 8.177083vw;
}

.swish-outside-b--02:after,
.swish-outside-b--05:after {
    height: 8.177083vw;
    background-image: url(../images/swish-sb-02.svg);
}

/* Outside Bottom - 02 */
.swish-outside-b--03,
.swish-outside-b--06 {
    margin-bottom: 5.5208333vw;
}

.swish-outside-b--03:after,
.swish-outside-b--06:after {
    height: 5.5208333vw;
    background-image: url(../images/swish-sb-03.svg);
}


/* ---- Outside Overlay ---- */
.swish-outside-overlay-b,
.swish-outside-overlay-t {
    z-index: 1;
}

.swish-outside-overlay-b {
    margin-bottom: 0;
}

.swish-outside-overlay-t {
    margin-top: 0;
}

/* ---- Padding compensation ---- */
/* Add padding to the section following an outside overlay or the overlay will sit above the section content */
.swish-section-padding-outside-b--02 {
    padding-top: 8.177083vw;
}

.swish-section-padding-outside-t--footer {
    padding-bottom: 5.3125vw;
}

.swish-content-padding-outside-b--01 .swish-section-padding-outside-b {
    padding-top: 7.65625vw;
}

.swish-content-padding-outside-b--02 .swish-section-padding-outside-b {
    padding-top: 8.177083vw;
}

.swish-content-padding-outside-b--03 .swish-section-padding-outside-b {
    padding-top: 5.5208333vw;
}

.swish-content-padding-outside-t--footer .widget {
    padding-bottom: 5.3125vw;
}

.swish-content-padding-outside-t--01 .swish-section-padding-outside-t {
    padding-bottom: 5.5556vw;
}

.swish-content-padding-outside-t--02 .swish-section-padding-outside-t {
    padding-bottom: 8.177083vw;
}

.swish-content-padding-outside-t--03 .swish-section-padding-outside-t {
    padding-bottom: 7.2395833vw;
}

// Dark variation
// 04 is the banner inside swish - used as 04 on the panel page
.bg-03.swish-outside-t--04:before {
    background-image: url(../images/swish-banner-dark.svg);
}


/* Outside Top - 02 */
.bg-03.swish-outside-t--02:before,
.bg-03.swish-outside-t--05:before {
    background-image: url(../images/swish-st-dark-02.svg);
}


/* Outside Top - 03 */
.bg-03.swish-outside-t--03:before,
.bg-03.swish-outside-t--06:before {
    background-image: url(../images/swish-st-dark-03.svg);
}
