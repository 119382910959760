.sidebar__cta {
	background-color: $color-02;
	color: $color-white;
	padding: 40px;

	h3 {
		font-size: $fs-r*1.5;
		margin-bottom: 1.5em;

		@include line-deco-below;
	}

	&__link {
		margin-bottom: 0;
	}
}


.richtext-cta {
	position: relative;
	background-color: $color-03;
	color: $color-white;
	padding-top: 40px;
	padding-right: 40px;
	padding-bottom: 40px;
	margin-top: 64px;
	box-shadow: 0 8px 0 rgba($color-03, 0.5);
	margin-bottom: 8px;

	@media screen and (min-width: 48em) { /* 768px */
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&:before {
		content: "";
		position: absolute;
		right: 100%;
		top: 0;
		bottom: 0;
		background-color: $color-03;
		width: 50vw;
		box-shadow: 0 8px 0 rgba($color-03, 0.5);
	}

	h2 {
		color: $color-white;
		display: block;
		margin-top: 0;
		flex: 0 0 auto;
		flex-basis: 100%;
		max-width: 100%;
	}

	p {
		margin-bottom: 0;
	}

	* + * {
		margin-bottom: 1.6rem;
	}

	a {
		color: $color-white;
		margin-top: 10px;

		@media screen and (min-width: 48em) { /* 768px */
			margin-top: 0;
		}
	}

	.button {
		margin-bottom: 0;
	}
}


.panel-page .widget-RichText {
	.richtext-cta:before {
		width: 6vw;

		/* BP small */
		@media screen and (min-width: 62em) { /* 992px */
			width: calc(4vw + 92vw * 0.083333333);
		}

		/* BP xlarge */
		@media screen and (min-width: 87.5em) {/* 1400px */
			width: calc((50vw - 640px) + 106.7px);
		}

		/* BP xxlarge */
		@media screen and (min-width: 100em) {/* 1600px */
			// 1392px * 0.083333333 = 116px
			width: calc((50vw - 696px) + 116px);
		}
	}
}

.mainbar__cta {
	h3 {
		@include line-deco-above;
	}
}

.section-swish-on-top .mainbar__cta {
	padding-top: 40px;
	padding-bottom: 40px;

	@media screen and (min-width: 48em) { /* 768px */
		padding-top: 80px;
		padding-bottom: 80px;
	}
}
