.panel-page .widget-RichText {
    /* BP small */
    @media screen and (min-width: 62em) { /* 992px */
        padding-left: calc(92vw * 0.083333333);
        padding-right: calc(92vw * 0.083333333);
    }

    /* BP xlarge */
    @media screen and (min-width: 87.5em) {/* 1400px */
        padding-left: calc(1280px * 0.083333333);
        padding-right: calc(1280px * 0.083333333);
    }

    /* BP xxlarge */
    @media screen and (min-width: 100em) {/* 1600px */
        padding-left: calc(1392px * 0.083333333);
        padding-right: calc(1392px * 0.083333333);
    }
}
