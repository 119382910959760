
/* ---- Text level elements ---- */
abbr[title] {
    border-bottom: 1px dotted #c6bfbf;
    cursor: help;
}

b, strong {
    font-weight: $fw-03;
}

dfn {
    font-style: italic;
}

ins {
    background-color: #f6f6f6;
    color: #473f3f;
    text-decoration: none;
}

mark {
    background-color: #f6f6f6;
    color: #473f3f;
    font-style: italic;
    font-weight: $fw-03;
}

pre,
code,
kbd,
samp {
    font-family: 'Monaco', 'Courier New', monospace;
    color: #484040;
    background: #efefef;
    background: rgba(0, 0, 0, .07);
    padding: 0 2px;
    margin: 0 -2px;
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -.5em;
}

sub {
    bottom: -.25em;
}

small {
    font-size: 1.28rem;
}
