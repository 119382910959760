.testimonial {

	&__content {
		position: relative;

		&__excerpt {
			font-size: $fs-r*1.25;
			line-height: 1.5;

			@media screen and (min-width: 48em) { /* 768px */
				font-size: $fs-r*1.5;
			}
		}
	}

	&__more-link {
		@media screen and (min-width: 48em) { /* 768px */
			position: absolute;
			right: 0;
		}

		a {
			color: $color-grey-07;
			position: relative;
			text-transform: lowercase;
			text-decoration: none;

			&:after {
				content: "";
				position: absolute;
				top: calc(100% - 1px);
				left: 0;
				right: 0;
				border-bottom: 1px solid $color-grey-07;
			}
		}
	}
}




.panel-page {
	.testimonial__content__excerpt {
		font-size: $fs-r*1.5;

		@media screen and (min-width: 48em) { /* 768px */
			font-size: $fs-r*2;
		}
	}

	.testimonial__content__excerpt--long {
		@media screen and (min-width: 48em) { /* 768px */
			font-size: $fs-r*1.5;
		}
	}

	.testimonial__col--image {
		@media screen and (min-width: 75em) { /* 1200px */
			margin-left: 8.33333333%;
		}
	}

	.testimonial__col--content {
		@media screen and (min-width: 75em) { /* 1200px */
			flex-basis: 66.66666667%;
			max-width: 66.66666667%;
		}
	}

	.testimonial__col--content--imageless {
		@media screen and (min-width: 75em) { /* 1200px */
			flex-basis: 83.33333333%;
			max-width: 83.33333333%;
			margin-left: 8.33333333%;
		}
	}
}

.sidebar {
	.testimonial-slider {
		background-color: $color-02;
		color: $color-white;
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 56px;
		padding-bottom: 72px;
		font-weight: $fw-02;

		.slick-dots {
			bottom: 32px;
			width: auto;

			li {
				width: 8px;
				height: 8px;

				button {
					width: 8px;
					height: 8px;

					&:before,
					&:after {
						background-color: #fff;
					}
				}
			}
		}
	}

	.testimonial {

		&__content__excerpt {
			@include line-deco-above;
			font-size: $fs-r*1.2;
			line-height: 1.5;
			padding-top: calc(1.5em + 8px);

			&:before {
				background: $color-00;
			}

			@media screen and (min-width: 48em) { /* 768px */
				font-size: $fs-r;
			}
		}

		&__author {
			margin-bottom: 0;
			font-weight: $fw-03;
		}
	}
}
