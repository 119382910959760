
.icon {
    display: inline-block;
    vertical-align: middle;
}

.icon svg {
    width: 16px;
    height: 16px;

    @media screen and (min-width: 100em) {/* 1600px */
        width: 18px;
        height: 18px;
    }
}

.icon--24px svg {
    width: 24px;
    height: 24px;
}

.icon--32px svg {
    width: 32px;
    height: 32px;
}

.icon--40px svg {
    width: 40px;
    height: 40px;
}

.icon--48px svg {
    width: 48px;
    height: 48px;
}

.icon--52px svg {
    width: 52px;
    height: 52px;
}

.icon--60px svg {
    width: 60px;
    height: 60px;
}

.icon--68px svg {
    width: 68px;
    height: 68px;
}


.icon--wider svg {
    width: 20px;
    height: 16px;

    @media screen and (min-width: 100em) {/* 1600px */
        width: 23px;
        height: 18px;
    }
}

/* Icon system colours */

.icon--primary svg path {
    fill: $color-primary;
}

.icon--00 svg path {
    fill: $color-00;
}

.icon--01 svg path {
    fill: $color-01;
}

.icon--02 svg path {
    fill: $color-02;
}

.icon--03 svg path {
    fill: $color-03;
}

.icon--grey-07 svg path {
    fill: $color-grey-07;
}

.icon--white svg path {
    fill: $color-white;
}

