.post-gallery {
    @media screen and (min-width: 75em) { /* 1200px */
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;

        margin: -20px;
    }

    .post-gallery-item--full {
        @media screen and (min-width: 75em) { /* 1200px */
            flex: 0 0 auto;
            flex-basis: 66.66666667%;
            max-width: calc(66.66666667% - 40px);
            margin: 20px;
        }
    }

    .post-gallery-item--condensed {
        @media screen and (min-width: 75em) { /* 1200px */
            flex: 0 0 auto;
            flex-basis: 33.33333333%;
            max-width: calc(33.33333333% - 40px);
            margin: 20px;
        }
    }
}
