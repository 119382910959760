* {
    margin: 0;
}

html,
body {
    background: #FFF;
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    line-height: 1.75;
    color: $color-grey-07;
    font-size: $fs-r;
    font-family: $primary-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: $fw-01;

    @media screen and (min-width: 100em) {
        font-size: $fs-r;
    }
}
