
.archive-links {
	margin-top: 20px;
}

.archive-links h3 {
	margin-bottom: 8px;
	margin-top: 16px;
}

.archive-links > h3:first-child {
	margin-top: 0;
}

.archive-links .linklist {
	margin-bottom: 0;
}

.archive-links .linklist li {
    padding-top: 0;
    padding-bottom: 0;
}

.archive-links .linklist > li {
    font-size: inherit;
	margin-bottom: 4px;
	margin-top: 16px;
}

.archive-links .linklist > li:first-child {
	margin-top: 0;
}

.archive-links .linklist a {
	padding-top: 8px;
    padding-bottom: 8px;
	margin-bottom: 0;
}

.archive-links .linklist a:after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' preserveAspectRatio='none' viewBox='0 0 10 8'%3E%3Cpath d='M0 4.03h9.006M6.172 6.858l3.19-3.179m-3.19-2.477l3.19 3.184' style='stroke:%235F707E%3B' /%3E%3C/svg%3E");
}

.archive-links .linklist a:hover:after,
.archive-links .linklist a:focus:after,
.archive-links .linklist a:active:after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' preserveAspectRatio='none' viewBox='0 0 10 8'%3E%3Cpath d='M0 4.03h9.006M6.172 6.858l3.19-3.179m-3.19-2.477l3.19 3.184' style='stroke:%2301232F%3B' /%3E%3C/svg%3E");
}

.archive-links .linklist a.active:after {
	content: "\2022";
	background: none;
	margin-left: 0;
}

.archive-links .linklist ul {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
}

.archive-links .linklist h4 {
	padding-left: 10px;
	margin-bottom: 0;
}

.archive-links .linklist > ul li a {
    padding-left: 20px;
    display: inline-block;
}

.archive-links .linklist > ul {
    padding-top: 8px;
}
