.subtitle {
    font-size: $fs-r*0.8;
    color: $color-01;
    text-transform: uppercase;
    line-height: 1.33;

    @media screen and (min-width: 48em) { /* 768px */
        font-size: $fs-r;
    }
}

@mixin subtitle {
    font-size: $fs-r*0.8;
    color: $color-01;
    text-transform: uppercase;
    line-height: 1.33;

    @media screen and (min-width: 48em) { /* 768px */
        font-size: $fs-r;
    }
}
