
$link-transition:   250ms ease-in-out;

/* Fade in */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Fade in down */
@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.animate-fade {
    animation: fadeInDown 400ms ease-in-out;
}

/* Fade in up */
@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(30px);
                transform: translateY(30px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(30px);
                transform: translateY(30px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
	from {
		opacity: 0;
		margin-bottom: -20px;
	}
	to {
		opacity: 1;
		margin-bottom: 0;
	}
}

@keyframes fadeInUpMargin {
	from {
		opacity: 0;
		margin-bottom: -20px;
	}
	to {
		opacity: 1;
		margin-bottom: 0;
	}
}

@-webkit-keyframes fadeInLeft {
	from {
		opacity: 0;
        -webkit-transform: translate(-50px, 50px);
                transform: translate(-50px, 50px);
	}
	to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
	}
}

@keyframes  fadeInLeft {
	from {
		opacity: 0;
        -webkit-transform: translate(-50px, 50px);
                transform: translate(-50px, 50px);
	}
	to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
	}
}

@-webkit-keyframes fadeInRight {
	from {
		opacity: 0;
        -webkit-transform: translate(50px, 50px);
                transform: translate(50px, 50px);
	}
	to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
	}
}

@keyframes  fadeInRight {
	from {
		opacity: 0;
        -webkit-transform: translate(50px, 50px);
                transform: translate(50px, 50px);
	}
	to {
        opacity: 1;
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
	}
}


/* ---- Bounce down ---- */
@-webkit-keyframes btnBounce {
    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    25% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px) scale(0.99, 1.01);
    }
    50% {
        -webkit-transform: translateY(0);
        transform: translateY(0) scale(1.01, 0.99);
    }
    75% {
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
    }
}

@keyframes btnBounce {
    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    25% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px) scale(0.99, 1.01);
    }
    50% {
        -webkit-transform: translateY(0);
        transform: translateY(0) scale(1.01, 0.99);
    }
    75% {
        -webkit-transform: translateY(-1px);
        transform: translateY(-1px);
    }
}


.demo-animate .demo-animate__item {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.demo-animate:hover .demo-animate__item {
    clip: auto;
    height: auto;
    overflow: visible;
    position: relative;
    width: auto;
    padding: 5px;
    -webkit-animation: fadeInDown 400ms ease-in-out;
            animation: fadeInDown 400ms ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
