.reverse-text {
    color: $color-white;
}

.reverse-text a {
    color: $color-white;
    opacity: 0.75;
}

.reverse-text a:hover,
.reverse-text a:focus,
.reverse-text a:active {
    opacity: 1;
    opacity: 0.75;
}

@mixin reverse-text {
    color: #ffffff;

    & a {
        color: inherit;
    }

    & a:hover,
    & a:focus,
    & a:active {
        opacity: 1;
    }
}

