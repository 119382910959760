
.section-cta {
    padding-top: 40px;
    padding-bottom: 0;
    text-align: center;
}

/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .section-cta {
        padding-top: 80px;
        padding-bottom: 0;
    }
}


/* BP xxlarge */
@media screen and (min-width: 100em) {/* 1600px */

}
