.banner-inner {

}

.swish--inner {
    @media screen and (min-width: 62em) { /* 992px */
        height: 6.25vw;
        background-color: $color-grey-01;
        //     margin-bottom: calc(8.38541667vw - 57px);
    }

    &:after {
        bottom: 0;
        background-position: center top;
        height: 8.463541667vw;
        background-image: url(../images/swish-inner-inverse.svg);

        @media screen and (min-width: 62em) { /* 992px */
            bottom: auto;
            top: 100%;
            background-position: center top;
            height: 8.38541667vw;
            background-image: url(../images/swish-inner.svg);
        }
    }
}

.banner-inner__img--mobile {
    @media screen and (min-width: 62em) { /* 992px */
        display: none;
    }
}

.banner-inner__img--desktop {
    display: none;

    @media screen and (min-width: 62em) { /* 992px */
        display: block;
    }
}

.banner-inner--imageless {
    .swish--inner {
        height: 6.25vw;
        background-color: $color-grey-01;
        margin-bottom: calc(8.38541667vw - 32px);

        &:after {
            bottom: auto;
            top: 100%;
            background-position: center top;
            height: 8.38541667vw;
            background-image: url(../images/swish-inner.svg);
        }
    }
}

