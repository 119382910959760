.page-header {
    @media screen and (min-width: 36em) { /* 576px */
        position: relative;
        z-index: 1;
        margin-top: -4.232vw;
    }

    @media screen and (min-width: 62em) { /* 992px */
        margin-top: -2.75vw;
        padding-bottom: 64px;
    }

    h1 {
        margin-bottom: 16px;
        @include line-deco-above;
        padding-top: 24px;

        @media screen and (min-width: 75em) { /* 1200px */
            margin-bottom: 24px;
        }
    }

    .page-header--no-crumb {
        margin-bottom: 0;
    }
}

.page-header--imageless {
    @media screen and (min-width: 36em) { /* 576px */
        margin-top: 4.232vw;
    }

    @media screen and (min-width: 62em) { /* 992px */
        margin-top: 2.75vw;
    }
}
