
.section--pre-footer {
    background: $color-grey-01;

    .row {
        margin-left: -1em;
        margin-right: -1em;

        .col-xs-12,
        .col-sm-6,
        .col-xs-shrink,
        .col-xs-grow {
            padding-left: 1em;
            padding-right: 1em;
        }
    }

    .footer__enews__tcs {
        font-size: $fs-s;
        margin-top: 1em;

        @media screen and (min-width: 36em) { /* 576px */
            margin-top: 0;
        }
    }
}

.section--pre-footer .footer__entities {
    margin-left: -0.5em;
    margin-right: -0.5em;
}

.footer__entities {
    list-style-type: none;
    padding-left: 0;

    &__item {
        display: block;
        padding-left: 0.5em;
        padding-right: 0.5em;
        margin-bottom: 1em;

        @media screen and (min-width: 36em) { /* 576px */
            margin-bottom: 0;
        }

            &__inner {
            display: block;
            padding-top: 56.25%;
            background-color: $color-white;
            background-repeat: no-repeat;
            background-size: 80%;
            background-position: center;
        }
    }
}

.footer__heading {
    margin-top: 0;
    @include line-deco-below;
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.footer__subtitle {
    margin-bottom: 0;
    @include subtitle;
}

.section--footer {
    background: $color-grey-01;
    text-align: center;
    text-transform: uppercase;
    font-size: $fs-r*0.8;

    @media screen and (min-width: 100em) {/* 1600px */
        font-size: $fs-r*0.8;
    }
}

.footer__site-attribution {
    svg path {
        fill: $color-grey-07;
    }

    a:hover svg path,
    a:focus svg path {
        fill: #fa7850;
    }
}

.footer__text {
    color: rgba($color-grey-07, 0.66);

    p {
        margin: 0;
        display: block;

        a {
            text-decoration: none;
            color: rgba($color-grey-07, 0.66);

            &:hover,
            &:focus {
                color: rgba($color-grey-07, 1);
            }
        }

        @media screen and (min-width: 36em) { /* 576px */
            display: inline;

            &:before {
                content: "\2003";
            }

            &:first-child:before {
                content: none;
            }
        }
    }
}

.footer__col {
    margin-bottom: 56px;


    @media screen and (min-width: 48em) { /* 768px */
        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 62em) { /* 992px */
        margin-bottom: 0;
    }

}

.footer__contact {
    &__list {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;
    }

    &__item {
        margin-bottom: 1em;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        line-height: 1.5;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__icon {
        display: block;
        margin-right: 0.75em;
        margin-top: 0.2em;

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &__title {
        font-weight: $fw-03;
        color: $color-01;
        line-height: 1.2;
    }

    &__copy {
        display: block;
        margin-top: calc(40px - 1.6em);
        color: rgba($color-grey-07, 0.75);

        a {
            text-decoration: none;
            position: relative;
            color: rgba($color-grey-07, 0.75);

            &:after {
                content: "";
                position: absolute;
                height: 1px;
                top: calc(100% - 1px);
                left: 0;
                right: 0;
                background-color: rgba($color-01, 0);
                transition: all $link-transition;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                color: rgba($color-grey-07, 1);

                &:after {
                    background-color: $color-01;
                }
            }
        }
    }
}
