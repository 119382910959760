.children-gallery-list {
    list-style-type: none;
    margin: 20px 0 10px;
    padding: 0 4px;

    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
}

.mainbar > .widget-ChildrenGallery > .children-gallery-list:first-child {
    margin-top: 0;
    margin-bottom: 0;
}

.widget-ChildrenGallery .children-gallery-list-item {
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 8px;
    text-align: left;

    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
}

.children-gallery-list--2 .children-gallery-list-item,
.children-gallery-list--3 .children-gallery-list-item,
.children-gallery-list--4 .children-gallery-list-item,
.children-gallery-list--5 .children-gallery-list-item {
    width: 50%;
    flex-basis: 50%;
    max-width: 50%;
}

.widget-ChildrenGallery .children-gallery-list-item-link {
    text-decoration: none;
    color: inherit;
    position: relative;
    cursor: pointer;
    color: $color-grey-07;
    background-color: transparent;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover {
    text-decoration: underline;
}

.widget-ChildrenGallery .children-gallery-list-item-image {
    opacity: 1;
}

.children-gallery-list-item-image-placeholder {
    background-color: $color-grey-01;
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url(../images/img_placeholder.svg);
    background-size: contain;
    width: 100%;
    opacity: 1;
    padding-bottom: 56.25%;
}

.widget-ChildrenGallery .children-gallery-list-item-link:hover .children-gallery-list-item-image {
    opacity: 0.85;
}

.widget-ChildrenGallery .children-gallery-list-item-title {
    font-size: $fs-r*1.15;
    z-index: 2;
    max-width: 100%;
    font-weight: $fw-01;
    line-height: 1.33;
    margin-top: 0.25em;
}

.widget-ChildrenGallery .children-gallery-list-item-anchor {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/* BP Smaller */
@media screen and (min-width: 35em) { /* 560px */
    .children-gallery-list--3 .children-gallery-list-item,
    .children-gallery-list--5 .children-gallery-list-item {
        width: 33%;
        flex-basis: 33%;
        max-width: 33%;
    }
}

/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .children-gallery-list--4 .children-gallery-list-item {
        width: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
}

@media only screen and (min-width: 62em) {
    .children-gallery-list--5 .children-gallery-list-item {
        width: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }
}

/* BP large */
@media screen and (min-width: 80em) { /* 1280px */
    .children-gallery-list-item-image {
        transition: all 250ms ease-in-out;
    }
}
