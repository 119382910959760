/* ---- Post Hub view ---- */
.post-hub__item__content {
    background-color: #fff;
    margin-bottom: 1.5rem;
}


/* ---- blog hub styles ---- */
.post-hub-filterlist {
    margin-top: -5px;
}

// .post-hub-filterlist__item .button {
//     font-size: 1.4rem;
//     padding-left: 10px;
//     padding-right: 10px;
//     background-color: $color-grey-04;
//     border: 3px solid $color-grey-04;
//     margin-top: 5px;
// }

// .post-hub-filterlist__item .button:hover,
// .post-hub-filterlist__item .button:focus {
//     background-color: $color-grey-05;
//     border: 3px solid $color-grey-05;
// }

.post-hub__body .post-list__author {
    display: none;
}

.post-hub__body h2 {
    margin-bottom: 8px;
}

.post-hub__body .post-list__date p {
    margin-bottom: 8px;
    font-size: $fs-r;
    color: $color-grey-04;
}

// /* 02 */
// @supports(display: block) {
//     .post-hub-filterlist__item .button {
//         padding-top: 3px;
//     }
// }


.wide .post-hub__body {
    text-align: left;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
}

.wide .post-hub__footer {
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
}

.post-hub__body {
    padding-top: 20px;
}

.post-hub__body h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: $fs-r*1.2;
    font-weight: $fw-03;
}

.post-hub__body h2 a {
    color: $color-grey-07;
    text-decoration: none;
}

.post-hub__body h2 a:hover,
.post-hub__body h2 a:focus {
    color: darken($color-grey-07, 10%);
}

.post-hub__footer p {
    margin-bottom: 0;
    width: 100%;
}

.post-hub__footer .button {
    max-width: unset;
}

.post-hub__item .widget-Video {
    background-color: $color-grey-09;
}

.post-view__cloud,
.post-list__cloud {
    display: flex;
    flex-wrap: wrap;
}

.post-view__cloud--tags:before,
.post-view__cloud--categories:before,
.post-list__cloud:before,
.post-list__cloud:before {
    display: inline-block;
    padding-top: 4px;
    text-transform: lowercase;
    color: $color-grey-04;
}

.post-view__cloud--tags:before,
.post-list__tags:before {
    content: "Tags:\00a0";
}

.post-view__cloud--categories:before,
.post-list__categories:before {
    content: "Categories:\00a0";
}

.post-hub__img-link {
    padding-top: 56.25%;
}


/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .post-hub {
        margin-right: -2rem;
        margin-left: -2rem;
    }

    .post-hub__item {
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .post-hub__item__content {
        margin-bottom: 4rem;
    }

    .post-list__author {
        display: none;
    }

    .post-hub__item {
		display: flex;
		flex-direction: column;
	}

	.accom-box__content,
	.post-hub__item__content {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
    }

    .post-hub__body {
		flex: 1 1 auto;
    }

    .post-hub__body .post-list__author {
        flex-basis: auto;
        max-width: unset;
        flex: 1 1 auto;
    }
    .post-hub__body .post-list__date {
        flex-basis: auto;
        max-width: unset;
    }
}

/* BP medium */
@media screen and (min-width: 62em) { /* 992px */
    .wide .post-hub__item__content {
        margin-bottom: 4rem;
    }

    .wide .post-hub__body {
        padding-top: 16px;
        padding-left: 32px;
        padding-right: 32px;
    }

    .wide .post-hub__footer {
        padding-bottom: 16px;
        padding-left: 32px;
        padding-right: 32px;
    }

    .post-hub__body .post-list__author {
        display: none;
    }

    .post-hub__body .post-list__date {
        flex-basis: auto;
        max-width: unset;
    }
}

@media screen and (min-width: 87.5em) {/* 1400px */
    .post-hub {
        margin-right: -2.4rem;
        margin-left: -2.4rem;
    }

    .post-hub__item {
        padding-right: 2.4rem;
        padding-left: 2.4rem;
    }

    .post-hub__item__content {
        margin-bottom: 4.8rem;
    }
}

/* ---- Blog go back button ---- */
.back-btn {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 20px;
}

.back-btn p {
    margin: 0;
    line-height: 18px;
}

.back-btn a {
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
}

.back-btn__icon {
    display: block;
    width: 24px;
    height: 18px;
    float: left;
    margin-right: 1em;
    margin-top: -1px;
}

.back-btn__icon svg path {
    fill: inherit;
    display: block;
}

.blog-post-w-image .back-btn {
    position: relative;
}

/* BP medium */
@media screen and (min-width: 62em) { /* 992px */
    /* Post with feature image */
    .blog-post-w-image .back-btn {
        margin-top: -361px;
    }

    .blog-post-w-image .back-btn a {
        color: #fff;
    }

    .blog-post-w-image .back-btn__icon svg path {
        fill: #fff;
    }
}

/* ---- Category styles ---- */
.post-view__cloud p,
.post-list__cloud {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}

.post-list__cloud {
    margin-bottom: -5px;
}

.post-view__cloud .button,
.post-list__cloud .button {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    max-width: unset;
    flex: 0 0 auto;
    font-size: 1.2rem;
    padding-left: 10px;
    padding-right: 10px;
    border-color: $color-grey-06;
    color: $color-white;

    &:after {
        background-color: $color-grey-06;
    }

    &:hover,
    &:focus {
        border-color: $color-grey-06;
        color: $color-grey-06;
    }
}

/* ---- Lists ---- */
.post-list {
    &__item {
        background-color: #fff;
        padding: 0;
        border-bottom: none;
        margin-bottom: 40px;
    }

    &__image {
        padding-left: 0;
        padding-right: 0;
        background-color: #ECECEC;
    }

    &__image img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    &__content {
        padding-right: 16px;
        padding-left: 16px;

        @media screen and (min-width: 35em) { /* 560px */
            padding-right: 24px;
            padding-left: 24px;
        }

        @media screen and (min-width: 62em) { /* 992px */
            padding-right: 40px;
            padding-left: 40px;
        }
    }

    &__author a {
        color: inherit;
    }

    &__body p {
        display: inline;
    }

    &__body {
        margin-bottom: 1.5rem;
        line-height: 1.5;
    }

    &__link,
    &__link a {
        text-transform: lowercase;
    }

    &__author a:hover,
    &__author a:focus,
    &__author a:active {
        color: darken($color-01, 10%);
    }

    &__heading {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.2;
    }

    &__heading a {
        color: inherit;
        text-decoration: none;
    }

    &__heading a:hover,
    &__heading a:focus,
    &__heading a:active {
        color: darken($color-01, 10%);
        text-decoration: underline;
    }
}

.post-list .post-list__heading {
    margin-top: 0;
    font-size: $fs-r*1.25;
}

/* BP Smaller */
@media screen and (min-width: 35em) { /* 560px */
    .post-list__heading {
        text-align: center;
    }
}


/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .post-list__heading {
        font-size: 2.8rem;
    }

    .post-list__author,
    .post-list__date {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .post-list__author p {
        margin-right: auto;
    }

    .post-list__date p {
        margin-left: auto;
        text-align: right;
    }
}

/* BP medium */
@media screen and (min-width: 62em) { /* 992px */
    .post-list__heading {
        text-align: left;
    }

    .post-list__image {
        background-color: transparent;
    }
}



.post-list {

    .post-list__date {
        text-align: left;

        @media only screen and (min-width: 62em) {
            text-align: left;
        }

         p {
            @media screen and (min-width: 48em) {
                margin-left: 0;
                text-align: left;
            }
        }
    }
}
