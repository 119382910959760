.tile-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        margin-bottom: 2em;
    }
}

@media screen and (min-width: 64em) { /* 1024px */
    .tile-list {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        margin-left: -1em;
        margin-right: -1em;

        &__item {
            margin-bottom: 0;
            flex-basis: 50%;
            max-width: 50%;
            padding-right: 1em;
            padding-left: 1em;
        }
    }
}

