

.row--vertical-gutters *[class*="col-xs"],
.row--vertical-gutters *[class*="col-sm"],
.row--vertical-gutters *[class*="col-md"],
.row--vertical-gutters *[class*="col-lg"] {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* ---- Row - nowrap  ---- */
.row-nowrap--xs {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

@media screen and (min-width: 36em) {
    .row-nowrap--xsm {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

@media screen and (min-width: 48em) {
    .row-nowrap--sm {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

@media screen and (min-width: 62em) {
    .row-nowrap--md {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

@media screen and (min-width: 75em) {
    .row-nowrap--lg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

@media screen and (min-width: 100em) {
    .row-nowrap--xlg {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}


/* ---- Unpadded row ---- */
.row-0 {
    margin-right: 0;
    margin-left: 0;
}

.row-0 > .col-xs,
.row-0 > .col-xs-1,
.row-0 > .col-xs-2,
.row-0 > .col-xs-3,
.row-0 > .col-xs-4,
.row-0 > .col-xs-5,
.row-0 > .col-xs-6,
.row-0 > .col-xs-7,
.row-0 > .col-xs-8,
.row-0 > .col-xs-9,
.row-0 > .col-xs-10,
.row-0 > .col-xs-11,
.row-0 > .col-xs-12,
.row-0 > .col-xs-grow,
.row-0 > .col-xs-shrink {
    padding-right: 0;
    padding-left: 0;
}


// Half extensions
@media screen and (min-width: 62em) { /* 992px */
    .col-md-7-half {
        flex-basis: 62.49999998%;
        max-width: 62.49999998%;
    }

    .col-md-offset-4-half {
        margin-left: 37.49999983%;
    }

    .col-md-offset-half {
        margin-left: 4.1666665%;
    }
}


/* ---- Column padding ---- */
.padded-x-col-1 {
    padding-left: calc(92vw * 0.083333333);
    padding-right: calc(92vw * 0.083333333);

    /* BP xlarge */
    @media screen and (min-width: 87.5em) {/* 1400px */
        padding-left: calc(1280px * 0.083333333);
        padding-right: calc(1280px * 0.083333333);
    }

    /* BP xxlarge */
    @media screen and (min-width: 100em) {/* 1600px */
        padding-left: calc(1392px * 0.083333333);
        padding-right: calc(1392px * 0.083333333);
    }
}

.padded-l-col-1 {
    padding-left: calc(92vw * 0.083333333);

    /* BP xlarge */
    @media screen and (min-width: 87.5em) {/* 1400px */
        padding-left: calc(1280px * 0.083333333);
    }

    /* BP xxlarge */
    @media screen and (min-width: 100em) {/* 1600px */
        padding-left: calc(1392px * 0.083333333);
    }
}

.padded-r-col-1 {
    padding-right: calc(92vw * 0.083333333);

    /* BP xlarge */
    @media screen and (min-width: 87.5em) {/* 1400px */
        padding-right: calc(1280px * 0.083333333);
    }

    /* BP xxlarge */
    @media screen and (min-width: 100em) {/* 1600px */
        padding-right: calc(1392px * 0.083333333);
    }
}


.padded-x-col-1--lg {
    @media screen and (min-width: 75em) { /* 1200px */
        padding-left: calc(92vw * 0.083333333);
    }
    padding-right: calc(92vw * 0.083333333);

    /* BP xlarge */
    @media screen and (min-width: 87.5em) {/* 1400px */
        padding-left: calc(1280px * 0.083333333);
        padding-right: calc(1280px * 0.083333333);
    }

    /* BP xxlarge */
    @media screen and (min-width: 100em) {/* 1600px */
        padding-left: calc(1392px * 0.083333333);
        padding-right: calc(1392px * 0.083333333);
    }
}

.padded-l-col-1--lg {
    @media screen and (min-width: 75em) { /* 1200px */
        padding-left: calc(92vw * 0.083333333);
    }

    /* BP xlarge */
    @media screen and (min-width: 87.5em) {/* 1400px */
        padding-left: calc(1280px * 0.083333333);
    }

    /* BP xxlarge */
    @media screen and (min-width: 100em) {/* 1600px */
        padding-left: calc(1392px * 0.083333333);
    }
}

.padded-r-col-1--lg {
    @media screen and (min-width: 75em) { /* 1200px */
        padding-right: calc(92vw * 0.083333333);
    }

    /* BP xlarge */
    @media screen and (min-width: 87.5em) {/* 1400px */
        padding-right: calc(1280px * 0.083333333);
    }

    /* BP xxlarge */
    @media screen and (min-width: 100em) {/* 1600px */
        padding-right: calc(1392px * 0.083333333);
    }
}

/* ---- Large padded row ---- */
.row-gap--lg {
    margin-right: -2vw;
    margin-left: -2vw;

    @media screen and (min-width: 87.5em) {/* 1400px */
        margin-right: -20px;
        margin-left: -20px;
    }
}

.row-gap--lg > .col-xs,
.row-gap--lg > .col-xs-1,
.row-gap--lg > .col-xs-2,
.row-gap--lg > .col-xs-3,
.row-gap--lg > .col-xs-4,
.row-gap--lg > .col-xs-5,
.row-gap--lg > .col-xs-6,
.row-gap--lg > .col-xs-7,
.row-gap--lg > .col-xs-8,
.row-gap--lg > .col-xs-9,
.row-gap--lg > .col-xs-10,
.row-gap--lg > .col-xs-11,
.row-gap--lg > .col-xs-12,
.row-gap--lg > .col-xs-grow,
.row-gap--lg > .col-xs-shrink {
    padding-right: 2vw;
    padding-left: 2vw;

    @media screen and (min-width: 87.5em) {/* 1400px */
        padding-right: 20px;
        padding-left: 20px;
    }
}
