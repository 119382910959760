
.section {
    padding-top: 40px;
    padding-bottom: 40px;
}

.section-small {
    padding-top: 24px;
    padding-bottom: 24px;
}

.section-large {
    padding-top: 60px;
    padding-bottom: 60px;
}

.section--t0 {
    padding-top: 0;
}

.section--b0 {
    padding-bottom: 0;
}


/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .section {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .section-small {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .section-large {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .section--t {
        padding-top: 80px;
    }

    .section--b {
        padding-bottom: 80px;
    }

    .section--t0 {
        padding-top: 0;
    }

    .section--b0 {
        padding-bottom: 0;
    }
}


/* BP xxlarge */
@media screen and (min-width: 100em) {/* 1600px */

}
