.search-paginate {
    text-align: center;
    padding: 1.5rem;
    background: $color-grey-03;
}
.search-paginate .page {
    display: inline-block;
    background: $color-01;
    color: #fff;
    padding: 0.5rem 1.25rem;
    text-decoration: none;
    text-align: center;
    margin: 0 0.25rem;
}
.search-paginate .page.on {
    background: darken($color-01, 20%);
}
.search-paginate .page-next {
    margin-left: 1.5rem;
}
.search-paginate .page-prev {
    margin-right: 1.5rem;
}

@supports (display: flex) {
    .search-paginate {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
