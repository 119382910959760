
.block-list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.block-list li {
    border-top: 1px solid rgba(0,0,0,0.2);
}

.block-list a {
    display: block;
    text-decoration: none;
    padding: 7px 0;
}
