
@mixin line-deco-above {
    display: block;
    position: relative;
    padding-top: calc(1em + 8px);

    &:before {
        content: '\00a0';
        position: absolute;
        top: 0;
        left: 0;
        height: 8px;
        width: 40px;
        background: $color-00;
    }
}

.line-deco-above {
    display: block;
    position: relative;
    padding-top: calc(1em + 8px);

    &:before {
        content: '\00a0';
        position: absolute;
        top: 0;
        left: 0;
        height: 8px;
        width: 40px;
        background: $color-00;
    }
}

.line-deco-above--shrunk {
    padding-top: 20px;
}

/* ---- Below ---- */
@mixin line-deco-below {
    display: block;
    position: relative;
    padding-bottom: calc(1em + 8px);

    &:before {
        content: '\00a0';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 8px;
        width: 40px;
        background: $color-00;
    }
}

.line-deco-below {
    display: block;
    position: relative;
    padding-bottom: calc(1em + 8px);

    &:before {
        content: '\00a0';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 8px;
        width: 40px;
        background: $color-00;
    }
}

.line-deco-below--shrunk {
    padding-bottom: 20px;
}

.line-deco--00:before {
    background: $color-00;
}

.line-deco--01:before {
    background: $color-01;
}


