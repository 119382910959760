/* ---- Magnific Popup ---- */
.mfp-image-holder .mfp-content {
    max-width: 1000px;
    background-color: $color-grey-09;
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
    background-color: #fff;
    margin: 0 auto;
    padding: 50px;
    max-width: 800px;
}

.mfp-figure figure {
    background: none;
    padding: 0;
    margin: 0;
}

.mfp-figure figcaption {
    margin-top: 0;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    right: 6px;
}

.mfp-hide {
    display: none !important;
}
