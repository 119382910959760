.card__list {
    padding:0;
    margin:0;
    list-style:none
}

@supports (display: grid) {
    .card__list {
        display:grid;
        grid-gap:32px;
        grid-template-columns:repeat(auto-fill, minmax(260px, 1fr))
    }

    .card__list--lg {
        grid-template-columns:repeat(auto-fill, minmax(320px, 1fr))
    }

    .card__item+.card__item {
        margin-top:0
    }
}
